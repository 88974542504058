import './App.css';
import ModelViewer from './ModelViewer';
import Ticket from './Ticket';
import React, { useState } from 'react';

function App() {
  const [showTickets, setShowTickets] = useState(false);
  const [shrinkGift, setShrinkGift] = useState(false);
  const [meshState, setMeshState] = useState({ rotationY: 0 });

  const handleAnimation = () => {
    setMeshState((prev) => ({ ...prev, rotationY: prev.rotationY + 0.001 }));
  };


  const handleGiftClick = () => {
    setShrinkGift(true);
    setTimeout(() => {
      setShowTickets(true);
    }, 7000);
  };

  return (
    <div className="App">
      <div className={`GiftContainer ${shrinkGift ? "shrink" : ""}`}>
        <ModelViewer
          modelPath="/gift/"
          modelFile="scene.gltf"
          onGiftClick={handleGiftClick}
        />
      </div>

      <div className="text">
        <p>Hlakka til að fara með þér til Florence í júlí. <br /> Gleðileg Jól❤️</p>
      </div>

      <div className={`TicketsContainer ${showTickets ? "animate" : ""}`}>
        <div className="ticket">
          <Ticket name={"HARALDUR FRIÐRIKSSON"} seat={"9B"} />
        </div>
        <div className="ticket">
          <Ticket name={"ÞÓRDÍS BJÖRNSDÓTTIR"} seat={"9A"} />
        </div>

      </div>
    </div>
  );
}

export default App;
