import './Ticket.css';

function Ticket({ name, seat }) {
  return (
    <div className="ticket">
      {/* Left Section */}
      <div className="ticket-left">
        <div className="ticket-header">
          <div className="ticket-info">
            <div className='label'>FROM:</div>
            <div className='location-initials'>KEF</div>
            <div className='location-name'>KEFLAVÍK</div>
            <div className='date-time'>Jul 06, 2025</div>
            <div className='date-time'>01:10 AM</div>
          </div>
          <div className="ticket-icon">
            <img src="/airplane.svg" alt="Airplane Icon" className="icon-airplane" />
           
          </div>
          <div className="ticket-info" style={{textAlign: 'right'}}>
            <div className='label'>TO:</div>
            <div className='location-initials'>FLR</div>
            <div className='location-name'>FLORENCE</div>
            <div className='date-time'>Jul 06, 2025</div>
            <div className='date-time'>09:10 AM</div>
          </div>
        </div>

        <div className='footer-left'>
          <div className='footer-item' style={{paddingRight: 40}}>
            <div className='label'>Passenger</div>
            <div className='value'>{name}</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Flight</div>
            <div className='value'>LH4555</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Seat</div>
            <div className='value'>{seat}</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Gate</div>
            <div className='value'>15</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Terminal</div>
            <div className='value'>2C</div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="ticket-right">
        <div className='boarding-pass'>BOARDING PASS</div>
        <div className='ticket-header'>
          <div className="ticket-info">
            <div className='label'>FROM:</div>
            <div className='location-initials'>KEF</div>
            <div className='location-name'>KEFLAVÍK</div>
            <div className='date-time'>Jul 06, 2025</div>
            <div className='date-time'>01:10 AM</div>
          </div>
          <div className="ticket-info" style={{textAlign: 'right'}}>
            <div className='label'>TO:</div>
            <div className='location-initials'>FLR</div>
            <div className='location-name'>FLORENCE</div>
            <div className='date-time'>Jul 06, 2025</div>
            <div className='date-time'>09:10 AM</div>
          </div>
        </div>

        <div className='footer-right'>
          <div className='footer-item passenger'>
            <div className='label'>Passenger</div>
            <div className='value'>{name}</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Flight</div>
            <div className='value'>LH4555</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Seat</div>
            <div className='value'>{seat}</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Gate</div>
            <div className='value'>15</div>
          </div>
          <div className='footer-item'>
            <div className='label'>Terminal</div>
            <div className='value'>2C</div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Ticket;
