import React, { useEffect, useRef, memo } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const ModelViewer = memo(function ModelViewer({ modelPath, modelFile, onGiftClick }) {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const meshRef = useRef(null);
  const rotationSpeed = useRef(0.002); // Default rotation speed

  useEffect(() => {
    if (!sceneRef.current) {
      // Initialize scene, camera, and renderer only once
      sceneRef.current = new THREE.Scene();
      sceneRef.current.background = new THREE.Color(0xffffff);

      cameraRef.current = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
      cameraRef.current.position.set(0, 1, 10);

      rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
      rendererRef.current.outputColorSpace = THREE.SRGBColorSpace;
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      rendererRef.current.setPixelRatio(window.devicePixelRatio);
      rendererRef.current.shadowMap.enabled = true;
      rendererRef.current.shadowMap.type = THREE.PCFSoftShadowMap;

      if (containerRef.current) {
        containerRef.current.appendChild(rendererRef.current.domElement); // Append renderer's DOM element
      }

      // Add lighting
      const spotLight = new THREE.SpotLight(0xffffff, 1, 100, 0.22, 1);
      spotLight.position.set(0, 25, 0);
      spotLight.castShadow = true;
      sceneRef.current.add(spotLight);

      // Load Model
      const loader = new GLTFLoader().setPath(modelPath);
      loader.load(
        modelFile,
        (gltf) => {
          meshRef.current = gltf.scene;

          meshRef.current.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
            }
          });

          meshRef.current.scale.set(0.1, 0.1, 0.1);
          meshRef.current.position.set(0, 0, 0);
          meshRef.current.rotation.x = 0.2;

          sceneRef.current.add(meshRef.current);
        },
        null,
        (error) => console.error('Error loading model:', error)
      );
    }

    const onWindowResize = () => {
      cameraRef.current.aspect = window.innerWidth / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', onWindowResize);

    const onClick = (event) => {
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();

      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, cameraRef.current);
      const intersects = raycaster.intersectObjects(sceneRef.current.children, true);

      if (intersects.length > 0) {
        rotationSpeed.current = 0.01; // Increase rotation speed
        setTimeout(() => {
          rotationSpeed.current = 0.002; // Reset rotation speed after 3 seconds
        }, 6000);
        onGiftClick();
      }
    };

    window.addEventListener('click', onClick);

    const animate = () => {
      requestAnimationFrame(animate);

      if (meshRef.current) {
        meshRef.current.rotation.y += rotationSpeed.current; // Use dynamic speed
      }

      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };

    animate();

    return () => {
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('click', onClick);
      rendererRef.current.dispose();
    };
  }, [modelPath, modelFile, onGiftClick]);

  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
});

export default ModelViewer;
